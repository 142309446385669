////////////////
//
//    Colors
//
////////////////

$gray: #8e8f91;
$gold: linear-gradient(126.66deg, #f2efdc 25.72%, #ffd96a 82.81%);
$blue: linear-gradient(95.28deg, #0ea6e7 -1.86%, #097aea 102.2%);
$pink_gradient: linear-gradient(135deg, #ef4098 0%, #df1451 100%);
$aqua_gradient: linear-gradient(135deg, #40efb0 0%, #14badf 100%);
$purple: linear-gradient(135deg, #a240ef 0%, #3014df 100%);
$black: #000000;

////////////////
//
//    Fonts
//
////////////////

$font_family_heading: "integral-cf";

$font_size_hero: 4rem;
$font_size_campaigns_title: 1.25rem;
$font_size_button1: 1.25rem;
$font_size_button2: 1rem;
$font_size_nft_title: 0.875rem;
$font_size_tabs: 0.75rem;
$font_size_menu_nft: 0.625rem;

$font_family_body: "nimbus-sans";

$font_size_sub_heading: 1.5rem;
$font_size_campaign_description: 1.25rem;
$font_size_tweets: 1.125rem;
$font_size_body: 1rem;
$font_size_tweet_account: 1rem;
$font_size_small: 0.675rem;

$font_family_data: "source-code-variable";

$font_size_big_numbers: 1.75rem;
$font_size_standard: 1rem;
$font_size_small: 0.875rem;
$font_size_x_small: 0.75rem;
$font_size_xx_small: 0.625rem;
$font_size_tiny: 0.5rem;
