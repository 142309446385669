//////////////////
//
//  Variables
//
//////////////////

@import "../../assets/scss/mixin";

//////////////////
//
//  Campaigns
//
//////////////////

.campaign {
  &-item {
    cursor: pointer;
    margin-bottom: 32px;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(40px);
    transition: 0.2s;
    position: relative;
    min-height: 480px;
    border: 2px solid #ffffff;

    &:hover {
      border-color: #d2ff00;
      //transform: scale(1.025) !important;
      //&:after {
      //  opacity: 1;
      //  filter: blur(10px);
      //}
    }

    &.animated {
      opacity: 1;
      transform: translateY(0);
    }

    .logo {
      img {
        width: auto;
        max-width: 100%;
      }
    }
    h3 {
      display: block;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 400;
    }
    p {
      display: block;
      font-size: 18px;
    }
    .expires {
      font-size: 16px;
      font-family: $font_family_data;
      margin-bottom: 0;
    }
    &.lg {
      .logo {
        padding: 15px 0 70px 0;
        img {
          height: 64px;
        }
      }
      .expires {
        margin-top: auto;
      }
    }
    &.md {
      .logo {
        padding: 5px 0 20px 0;
        img {
          height: 37px;
        }
      }
      .expires {
        margin-top: 60px;
      }
    }
    &.sm {
      background: #101014 !important;
      .logo {
        padding: 35px 0 10px 0;
        img {
          height: 30px;
        }
      }
      .expires {
        margin-top: 24px;
      }
    }
  }

  &-item-col {
    max-width: 372px;
  }
}

@media screen and (max-width: 767px) {
  .campaign {
    &-wrap {
      width: 100%;
    }
    &-item {
      max-width: 372px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
