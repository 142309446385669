//////////////////
//
//  Variables
//
//////////////////

@import "../../assets/scss/mixin";

//////////////////
//
//  Campaigns
//
//////////////////

.tweet-action {
  display: inline-block;
  background: transparent;
  width: 100%;
  height: 180px;
  padding: 0;
  position: relative;
  z-index: 2;
  cursor: default;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: inline-block;
    height: 80px;
    width: auto;
    transition: 0.2s;
  }
  &.like.activated {
    background-color: #ed3e08;
    svg {
      path {
        fill: #000000;
      }
    }
  }
  &.quote.activated {
    background-color: #d2ff00;
    svg {
      path {
        fill: #000000;
      }
    }
  }
  &.comment.activated {
    background-color: #6000ff;
    svg {
      path {
        fill: #000000;
      }
    }
  }
  &:not(.activated) {
    cursor: pointer;
    &:hover {
      &.like {
        svg {
          transform: scale(0.92);
        }
      }
      &.quote {
        svg {
          transform: rotate(-10deg);
        }
      }
      &.comment {
        svg {
          transform: translateY(-8px);
        }
      }
      &.skip {
        svg {
          transform: translateX(16px);
        }
      }
    }
  }
  &.clickanimation {
    animation: clickanimation 0.15s linear;
  }
}

@keyframes clickanimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}

.modal-tweet {
  background: #000000;
  border-radius: 0;
  border: 1px solid #ffffff;
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: row;

  .modal-content {
    width: 78%;
    display: flex;
    flex-direction: row;
    border-right: 1px solid;

    .user-image {
      max-width: 100px;
      padding: 20px 0 20px 20px;
    }
    .user-tweet {
      position: relative;
      width: 100%;
      padding: 20px 20px 20px 20px;
      .tweet-modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    textarea {
      height: 240px;
      padding-right: 30px;
      font-size: 20px;
      line-height: 28px;
      resize: none;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      color: white;
      border: none;
    }
    .mobile-tags {
      display: none;
    }
    .tweet-control {
      display: flex;
      flex-direction: row;
      .control-item {
        display: flex;
        align-items: flex-end;
        &.control-item-upload {
          margin-left: 32px;
        }
        &.control-item-characters {
          margin-left: auto;
          color: rgba(255, 255, 255, 0.5);
          font-family: $font_family_data;
        }
        &.control-item-button {
          margin-left: 40px;
        }
      }
    }
    .tweet-button {
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #ffffff;
      border-radius: 0;
      height: 38px;
      width: 160px;
      &[disabled] {
        opacity: 0.5;
      }
    }
    .tweet-message {
      p {
        margin-bottom: 0;
        opacity: 0.8;
        font-size: 0.9rem;
      }
    }
  }

  .modal-tags {
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .tag {
      &-item {
        border-radius: 0;
        border: 1px solid #ffffff;
        padding: 3.5px;
        margin-bottom: 16px;
        cursor: pointer;
        transition: 0.2s;
        color: #000000;
        background-color: #ffffff;
        display: block;
        width: 100%;
        max-width: 120px;
        text-align: center;
        font-family: $font_family_data;
        font-size: $font_size_x_small;
        &:last-child {
          margin-bottom: 0;
        }
        &:not(.disable):hover {
          transform: scale(0.98);
        }
        &.disable {
          opacity: 0.4;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .modal-tweet {
    max-width: 470px;
    .modal-content {
      width: 100%;
      border-right: 0;
      flex-direction: column;
      position: relative;
      .user-image {
        max-width: 100%;
        padding: 20px 20px 0 20px;
      }
      .user-tweet {
        position: static;
        textarea {
          padding: 0;
        }
      }
      .mobile-tags {
        display: block;
        width: 100%;
        margin-bottom: 15px;
        .modal-tags {
          display: flex;
          width: 100%;
          flex-direction: row;
          padding: 0;
          padding-bottom: 20px;
          .tag-item {
            margin-top: 5px;
            margin-bottom: 0;
            margin-right: 15px;
          }
        }
      }
    }
    .modal-tags {
      display: none;
    }
  }
}
@media screen and (max-width: 992px) {
  .modal-tweet {
    .modal-content {
      .modal-tags {
        .tag-item {
          max-width: max-content;
        }
      }
      .tweet-control {
        flex-wrap: wrap;
        .control-item.control-item-button {
          flex-basis: 100%;
          margin-left: 0;
          .tweet-button {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.notify-modal {
  background-color: #000;
  .button.default {
    background-color: #fff;
    color: #000;
    border: 0;
    margin: 10px auto;
    font-family: "Helvetica";
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    min-width: 150px;
    padding: 7px;
  }
}

.tweet-wrap {
  position: relative;
}
.tweet-content-wrap {
  min-height: 450px;
}

.campaign-content {
  .tweet-action-wrap {
    max-width: 180px;
    min-width: 180px;
    padding: 0;
  }
  .tweet-content-wrap {
    padding: 0;
    max-width: 470px;
    min-width: 470px;
    min-height: 600px;
  }
  .tweet-action-wrap-left {
    padding-top: 41px;
  }
  .tweet-action-wrap-right {
    padding-bottom: 58px;
  }
}

.mobile-tweet-action {
  display: none !important;
  .tweet-action {
    height: 60px;
    svg {
      height: 50%;
      width: auto;
    }
  }
}

@media screen and (max-width: 1200px) {
  .campaign-content {
    .tweet-action-wrap {
      max-width: 140px;
      min-width: 140px;
      padding: 0;
    }
    .tweet-content-wrap {
      padding: 0;
      min-width: 420px;
      max-width: 420px;
      min-height: 550px;
    }
  }
}

@media screen and (max-width: 992px) {
  .campaign-content {
    .tweet-action-wrap {
      display: none !important;
    }
    .tweet-content-wrap {
      padding: 0;
      width: 100%;
      max-width: 470px;
      min-width: 470px;
      width: 100%;
      min-height: 500px;
    }
  }
  .mobile-tweet-action {
    display: flex !important;
  }
}

@media screen and (max-width: 766px) {
  .campaign-content {
    .tweet-content-wrap {
      min-width: auto;
    }
  }
}
