.affiliate-logo {
  height: 60px;
}

.campaign-logo {
  height: 60px;
}

@media screen and (max-width: 767px) {
  .affiliate-logo {
    max-width: 150px;
    height: auto;
  }
}

@media screen and (max-width: 470px) {
  .affiliate-logo {
    max-width: 100px;
    height: auto;
  }
}
