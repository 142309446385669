.product {
  &-image {
    min-height: 600px;
    overflow: hidden;
    border-radius: 10px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  &-details {
    padding: 0 60px;
    min-height: 70%;
    .title-wrap {
      font-family: "Helvetica";
      p {
        font-weight: bold;
        font-size: 18px;
      }
      h1 {
        font-weight: bold;
        font-size: 40px;
      }
      h2 {
        font-weight: bold;
        font-size: 24px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
    .description-wrap {
      margin-top: 64px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 1.4em;
      color: #ffffff;
      p {
        margin: 0.25em 0;
      }
      & > p:first-child {
        margin-bottom: 64px;
      }
    }
    .button-wrap {
      font-family: "Helvetica";
      font-size: 14px;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      margin-top: 64px;
      .button-buy {
        color: #fff;
        background: linear-gradient(95.28deg, #0ea6e7 -1.86%, #097aea 102.2%);
        border-radius: 4px;
        font-family: "Helvetica";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        border: 0;
        padding: 12px 27px;
      }
    }
  }
}
