//////////////////
//
//  Variables
//
//////////////////

@import "../../assets/scss/mixin";

//////////////////
//
//  TweetBox
//
//////////////////

.tweet-box {
  border: 1px solid #ffffff;
  width: 100%;
  min-height: 540px;
  .tweet-user {
    padding: 2rem 2rem 0 2rem;
    .tweet-user-image {
      border-radius: 90px;
      overflow: hidden;
      width: 40px;
      height: 40px;
      margin-right: 1rem;
      img {
        width: 100%;
        height: auto;
        &[src=""] {
          display: none;
        }
      }
    }
    .tweet-user-name {
      font-weight: 700;
    }
    .tweet-user-username {
      font-size: 14px;
      margin-top: 3px;
    }
    .tweet-createdAt {
      font-family: $font_family_data;
    }
  }
  .tweet-text {
    padding: 1rem 2rem;
    word-wrap: break-word;
    span,
    a {
      color: #00a3ff !important;
    }
  }
  .tweet-image {
    padding: 0 2rem 2rem 2rem;
    .tweet-image-wrap {
      overflow: hidden;
      max-height: 240px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .tweet-statistics {
    height: 60px;
    border-top: 1px solid #ffffff;
    & > div {
      width: 33.33%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 1600px) {
  .tweet-box {
    .tweet-text {
      font-size: 18px;
    }
  }
}
