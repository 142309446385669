//////////////////
//
//  Variables
//
//////////////////

@import "../../assets/scss/mixin";

////////////////
//
//    Profile
//
////////////////

.profile {
  &-banner {
    height: 160px;
    width: 100%;
    img {
      height: 100%;
      width: auto;
    }
  }

  &-details {
    align-items: center;
    position: relative;
    z-index: 2;
    margin-top: -40px;
    margin-bottom: 70px;
    margin-left: 15px;
    .image {
      width: 114px;
      height: 114px;
      overflow: hidden;
      border-radius: 100px;
      border: 3px solid #fff;
      img {
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
    .user-info {
      padding-left: 20px;
      padding-top: 35px;
      & > div:first-child {
        font-family: $font_family_heading;
        line-height: 1.2em;
        color: #ffffff;
      }
      & > div:last-child {
        font-size: 16px;
        line-height: 1.4em;
      }
    }
  }

  &-stat-item {
    text-align: left;
    font-family: $font_family_data;
    font-size: $font_size_big_numbers;
    font-style: normal;
    font-weight: 800;
    line-height: 1.2em;
    color: #fff;
    span {
      font-family: $font_family_data;
      font-style: normal;
      font-weight: 800;
      //font-size: $font_size_xx_small;
      font-size: 8px;
      line-height: 1.4em;
      text-transform: uppercase;
      color: #fff;
    }
  }
}
