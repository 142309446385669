.nft {
  &-item {
    border-radius: 0;
    cursor: pointer;
  }

  &-details {
    padding-left: 24px;
    padding-right: 24px;

    &-top {
      padding-top: 26px;
      padding-bottom: 34px;
    }

    &-bottom {
      padding-top: 16px;
      padding-bottom: 16px;

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
      }

      div {
        font-weight: bold;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;

    &-wrap {
      border-radius: 8px;
      overflow: hidden;
      max-width: 269px;
      height: 337px;
      width: 85%;
      margin: 0 auto;
    }
  }
}
