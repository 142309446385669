//////////////////
//
//  Variables
//
//////////////////

@import "../../assets/scss/mixin";

//////////////////
//
//  Campaigns
//
//////////////////

.topbar-row {
  height: 143px;
}
