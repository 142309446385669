//////////////////
//
//  Variables
//
//////////////////

@import "../../assets/scss/mixin";

////////////////
//
//    Challenges
//
////////////////

.challenges-feed {
  max-width: 470px;
}

.challenge-item-wrap {
  min-height: 296px;
  max-width: 570px;
  .challenge-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    opacity: 0;
    transform: translateY(40px);
    transition: 0.2s;
    border-color: #4b4c4e;
    &:hover {
      border-color: #d2ff00;
    }
    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
    h2 {
      margin-top: 0;
      margin-bottom: 7px;
      //font-size: $font_size_sub_heading;
      font-size: 1.25rem;
    }
    p {
      margin: 0;
    }
  }
}

.challenge-submitted {
  max-width: 600px;
  width: 100%;
  align-items: center;
  h2 {
    margin-top: 0;
    font-size: 32px;
    text-align: center;
    margin-bottom: 10px;
  }
  p {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px;
  }
  svg {
    display: inline-block;
  }
  .button {
    width: 100%;
    color: #000;
    margin-top: 16px;
    padding: 10px;
    background-color: #ddfc0f;
    border: 0;
    font-family: $font-family_heading;
    margin-top: 60px;
  }
}

.challenge-item-large {
  min-height: 680px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 10px;
    font-size: 32px;
    margin-bottom: 40px;
  }
  p {
    margin: 0;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .challenge-item-large {
    min-height: 270px;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
  .action-wrap {
    margin-top: 50px;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
}

.action-wrap {
  width: 100%;
  max-width: 480px;
  position: relative;
  a {
    color: #4a92ff;
  }
  label {
    font-family: $font_family_heading;
    font-size: 20px;
  }
  input {
    border: 1px solid #fff;
    background-color: transparent;
    padding: 16px;
    margin-top: 16px;
  }
  .challenge-submit {
    width: 100%;
    color: #000;
    margin-top: 16px;
    padding: 10px;
    background-color: #ddfc0f;
    border: 0;
    font-family: $font-family_heading;
    height: 40px;
    svg {
      height: 20px;
      width: auto;
      margin-right: 10px;
    }
    &[disabled] {
      background-color: #8e8f91;
    }
  }
  .button-wrap {
    position: relative;
    margin-top: 16px;
    input {
      width: 100%;
      margin-top: 0;
      padding-right: 60px;
    }
    .copied {
      position: absolute;
      bottom: -22px;
      right: 2px;
      color: #d2ff00;
      font-size: 0.9rem;
      opacity: 0;
      transition: 0.2s;
    }
    .button-copy {
      position: absolute;
      top: 0;
      right: 0;
      border: 1px solid #fff;
      width: 50px;
      height: 100%;
      background: transparent;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      svg {
        height: 25px;
        * {
          stroke: #fff;
          fill: #fff;
        }
      }
    }
  }
  .error-message {
    position: absolute;
    top: 100%;
    p {
      font-family: $font_family_data;
      color: #ed3e08;
    }
  }
}

.box-list {
  width: 100%;
  border-top: 1px solid #4b4c4e;
  margin-top: auto;
  .box-list-item {
    border-right: 1px solid #4b4c4e;
    font-family: $font_family_heading;
    text-transform: uppercase;
    font-size: $font_size_x_small;
    &:last-child {
      border-right: 0;
      font-family: $font_family_data;
      font-size: $font_size_x_small;
      text-transform: none;
    }
  }
}
