.dropdown-wrap {
  font-weight: bold;
  font-size: 36px;
  font-family: "Helvetica";
  color: #fff;
}

.switch-button-wrap {
  padding: 2px;
  background: #141518;
  border: 1.5px solid rgba(58, 72, 107, 0.5);
  border-radius: 2px;

  .switch-button {
    border-radius: 2px;
    border: 0;
    margin: 2px;
    padding: 8px 16px;
    min-width: 90px;
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 12px;
    &.active {
      background: linear-gradient(135deg, #0eace7 0%, #086eeb 100%);
      box-shadow: 0 20px 40px rgba(14, 128, 212, 0.3);
      color: #fff;
    }
  }
}

.ranking-table {
  width: 100%;
  border-collapse: separate;
  border-top: 1px solid rgba(58, 72, 107, 0.5);
  border-spacing: 0 0.5em;

  td,
  th {
    padding: 24px;
    text-align: left;
  }

  tbody tr {
    background: rgba(19, 20, 22, 0.8);
    border: 2px solid rgba(58, 72, 107, 0.5);
    box-shadow: inset 2px 2px 8px rgba(4, 4, 5, 0.6);
    border-radius: 12px;
  }

  .position {
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 16px;
    line-height: 1.4;
    &-changes {
      font-family: "Helvetica";
      font-weight: bold;
      font-size: 10px;
      line-height: 1.4;
    }
  }

  .player {
    .image-wrap {
      overflow: hidden;
      border-radius: 90px;
      width: 48px;
      height: 48px;
      img {
        object-fit: contain;
        object-position: center;
      }
    }
    .name {
      font-family: "Helvetica";
      font-weight: bold;
      font-size: 16px;
      line-height: 1.2;
    }
    .username {
      font-family: "Montserrat";
      font-size: 16px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .level {
    padding: 16px;
    background: linear-gradient(95.28deg, #0ea6e7 -1.86%, #097aea 102.2%);
    border-radius: 2px;
    color: #fff;
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    width: fit-content;
  }

  .reputation,
  .followers,
  .tweets {
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 1.4;
    text-align: right;
    color: #ffffff;
  }
}
