.loader,
.loader:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  border-top: 1em solid rgba(255, 255, 255, 0.8);
  border-right: 1em solid rgba(255, 255, 255, 0.4);
  border-bottom: 1em solid rgba(255, 255, 255, 0.8);
  border-left: 1em solid rgba(255, 255, 232, 0.4);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 2s infinite linear;
  animation: loader 2s infinite linear;
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
