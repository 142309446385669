//////////////////
//
//  Fonts
//
//////////////////

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-Regular.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-Regular.ttf") format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-Regular.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-RegularOblique.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-RegularOblique.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-RegularOblique.ttf")
      format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-RegularOblique.woff")
      format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-RegularOblique.woff2")
      format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-Medium.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-Medium.ttf") format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-Medium.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-MediumOblique.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-MediumOblique.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-MediumOblique.ttf")
      format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-MediumOblique.woff")
      format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-MediumOblique.woff2")
      format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-DemiBold.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-DemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-DemiBold.ttf") format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-DemiBold.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-DemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-DemiBoldOblique.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-DemiBoldOblique.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-DemiBoldOblique.ttf")
      format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-DemiBoldOblique.woff")
      format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-DemiBoldOblique.woff2")
      format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-Bold.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-Bold.ttf") format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-Bold.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-BoldOblique.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-BoldOblique.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-BoldOblique.ttf")
      format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-BoldOblique.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-BoldOblique.woff2")
      format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-ExtraBold.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-ExtraBold.ttf") format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-ExtraBold.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-ExtraBoldOblique.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-ExtraBoldOblique.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-ExtraBoldOblique.ttf")
      format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-ExtraBoldOblique.woff")
      format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-ExtraBoldOblique.woff2")
      format("woff2");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-Heavy.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-Heavy.ttf") format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-Heavy.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "integral-cf";
  src: url("/assets/fonts/integral-cf/IntegralCF-HeavyOblique.eot");
  src: url("/assets/fonts/integral-cf/IntegralCF-HeavyOblique.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/integral-cf/IntegralCF-HeavyOblique.ttf")
      format("truetype"),
    url("/assets/fonts/integral-cf/IntegralCF-HeavyOblique.woff") format("woff"),
    url("/assets/fonts/integral-cf/IntegralCF-HeavyOblique.woff2")
      format("woff2");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "source-code-variable";
  src: url("/assets/fonts/source-code-variable/Source-Code-Variable.eot");
  src: url("/assets/fonts/source-code-variable/Source-Code-Variable.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/source-code-variable/Source-Code-Variable.ttf")
      format("truetype"),
    url("/assets/fonts/source-code-variable/Source-Code-Variable.woff")
      format("woff"),
    url("/assets/fonts/source-code-variable/Source-Code-Variable.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
}
