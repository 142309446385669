//////////////////
//
//  Variables
//
//////////////////

@import "mixin";

html,
body {
  width: 100%;
}

body {
  font-family: $font_family_body;
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font_family_heading;
  font-weight: bold;
  font-style: normal;
}

.max-width-118 {
  max-width: 118px;
}

.h-100 {
  height: 100%;
}

a {
  color: #fff !important;
  stroke: #fff !important;

  &.active,
  &:hover {
    svg rect,
    svg path,
    svg circle {
      stroke: #fff !important;
    }
    span {
      color: #fff !important;
    }
  }
}

//////////////////
//
//  Header
//
//////////////////

.header {
  padding: 0.2rem 2rem;
  height: 9rem;
  position: relative;
  display: flex;
  align-items: center;

  .hamburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 28px;
    border: 0;
    background-color: transparent;
    padding: 0;

    &:focus,
    &:active {
      border: 0;
      outline: 0;
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      position: absolute;
      width: 30px;
      height: 2px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;
      border-radius: 0;
      background-color: #fff;
    }

    .hamburger-inner {
      top: 4px;
    }

    .hamburger-inner:before {
      top: 10px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform, opacity;
    }

    .hamburger-inner:after {
      top: 20px;
      bottom: -10px;
    }

    .hamburger-inner:after,
    .hamburger-inner:before {
      display: block;
      content: "";
    }

    &.open {
      .hamburger-inner {
        transform: translate3d(0, 10px, 0) rotate(45deg);
        //background-color: #ffffff;
      }
      .hamburger-inner:before {
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        opacity: 0;
        //background-color: #ffffff;
      }
      .hamburger-inner:after {
        transform: translate3d(0, -20px, 0) rotate(-90deg);
        //background-color: #ffffff;
      }
    }
  }
  .mobile-menu {
    flex-basis: 100%;
    display: none;
    padding: 10px 0;
    background: #000;
    z-index: 9;
    top: 9rem;
    position: absolute;
    width: 100%;
    overflow: hidden;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 100%;
    left: 0;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 100%;
      li {
        padding: 15px 0;
        border-bottom: 1px solid;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
    &.open {
      height: auto;
      display: flex;
    }
  }
}

@media screen and (min-width: 993px) {
  .header {
    .mobile-menu {
      display: none !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 6.75rem;
    .mobile-menu {
      top: 6.75rem;
    }
  }
}

//////////////////
//
//  Content
//
//////////////////

.content {
  padding: 2.625rem 2rem;
}

.box {
  border: 1px solid #ffffff;
  //box-shadow: inset 2px 2px 8px rgba(4, 4, 5, 0.6);
}

.button {
  &[disabled] {
    background-color: #8e8f91;
    cursor: default !important;
    &:hover {
      svg {
        transform: scale(1) !important;
      }
    }
  }
  &-balance {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: transparent;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Source Code Pro", monospace;
    height: 32px;
    width: 180px;
  }
  &-tweet-now {
    width: 100%;
    background: transparent;
    border: 1px solid #ffffff;
    font-family: $font_family_heading;
    font-size: $font_size_button1;
    text-transform: uppercase;
    line-height: 55px;
    &:not([disabled]):hover {
      color: #000;
      background-color: #fff;
    }
    &[disabled] {
      background-color: transparent;
      opacity: 0.2;
    }
  }
  &.logout {
    background: transparent;
    width: 100%;
    max-width: 356px;
    color: #ffffff;
    border: 1px solid #ffffff;
    height: 40px;
    font-family: $font_family_heading;
    text-transform: uppercase;
    margin-top: 10rem;
    font-size: 14px;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
  .social {
    background-color: transparent;
  }
  &.tweet-action {
    &[disabled] {
      background-color: transparent;
      opacity: 0.2;
    }
  }
  &-back {
    border: none;
    font-family: $font_family_data;
    color: #ffffff;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 15px;
    }
  }
  &.default {
    border: 1px solid #fff;
    background-color: transparent;
    height: 40px;
    line-height: 24px;
    min-width: 200px;
    font-size: 12px;
    font-family: $font_family_heading;
    text-transform: uppercase;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
}

.page-title {
  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: $font_size_hero;
    margin: 0;
    text-transform: uppercase;
    margin-left: 15px;
  }
}

.menu {
  width: 100%;
  margin-top: 90px;
  padding: 9px 0 11px 0;
  border-color: #4b4c4e;

  a {
    span {
      font-family: $font_family_heading;
      font-size: $font_size_menu_nft;
    }

    &.active,
    &:hover {
      svg rect,
      svg path,
      svg circle {
        stroke: #fff !important;
      }
      span {
        color: #fff !important;
      }
    }
  }
}

.hidden {
  display: none;
}

.feed-selector {
  border-radius: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background: transparent;
  margin-bottom: -1px;

  button {
    height: 40px;
    width: 33.33%;
    font-family: $font_family_heading;
    padding: 11px 25px;
    font-size: $font_size_tabs;
    border: 0;
  }
}

.MobileOverlay {
  height: 100vh;
  svg {
    max-width: 90%;
    height: auto;
  }
  p {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .feed-selector {
    button {
      padding: 11px;
    }
  }
}

.dropdown {
  position: relative;
  z-index: 3;
  width: 100%;

  .dropdown-options {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    //backdrop-filter: blur(40px);
    margin: 0;
    list-style: none;
    padding: 10px;
    display: none;
    z-index: -1;
    cursor: default;
    background-color: #000;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      // TODO: Remove this styles after created button component globally

      button {
        cursor: pointer;
        background: transparent;
        color: #fff;
        border: 0;
        height: 60px;
        width: 100%;
        min-width: 200px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        display: flex;
        align-items: center;
        padding: 20px;
        img,
        svg {
          margin-right: 15px;
        }
        &:hover {
          background: #fff;
          color: #000;
          svg {
            path {
              fill: #000;
            }
          }
        }
        &[disabled],
        &[disabled]:hover {
          opacity: 0.5;
          background: transparent;
          cursor: default;
        }
      }
    }
  }

  &:not(.disabled) {
    .dropdown-options {
      display: block;
      z-index: 2;
    }
  }
}

@media screen and (max-width: 992px) {
  .dropdown .dropdown-options {
    top: initial;
    bottom: 50%;
  }
}

//////////////
//
// Login page
//
//////////////

.twitter-login,
.metamask-login {
  border: 2px solid #ddfc0f;
  background-color: transparent;
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: $font_family_heading;
}

.loginLogo {
  position: fixed;
  top: 50px;
  left: 60px;
  z-index: 10;
}

//@media screen and (min-width: 1600px) {
//body {
//  font-size: 18px;
//}
//}

@media screen and (max-width: 1200px) {
  .page-title {
    h1 {
      font-size: 3rem;
    }
  }
}

@media screen and (max-width: 767px) {
  html,
  body {
    overflow-x: hidden;
  }
  .header,
  .content {
    padding: 2rem 0 1rem 0;
  }
  .content {
    padding-bottom: 3rem;
  }
  .page-title {
    h1 {
      font-size: 1.75rem;
    }
  }
}
