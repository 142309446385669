////////////////
//
//    Normalize
//
////////////////

@import "~normalize.scss";

////////////////
//
//    Fonts
//
////////////////

@import "assets/scss/fonts";

////////////////
//
//    Bootstrap
//
////////////////

//$grid-gutter-width: 16px !default;
@import "~bootstrap/scss/bootstrap-grid";

.pb-6rem {
  padding-bottom: 6rem;
}
.mb-75rem {
  margin-left: 7.5rem;
}

//////////////////
//
//  Variables
//
//////////////////

@import "assets/scss/mixin";

////////////////
//
//    Global Styles
//
////////////////

@import "assets/scss/global";
